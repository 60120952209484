import React from 'react'
import PasswordMask from 'react-password-mask'
import ErrorMessage from '../../../Errors'
import ModalRecoverPassword from '../../../Modal/RecoverPassword'

export default (props) => {
    return (
        <>
            <form onSubmit={props.handleSubmit} className="frm-login">
                <input
                    type="email"
                    name="email"
                    value={props.email}
                    onChange={props.handleChange}
                    placeholder="E-MAIL"
                    required
                />
                <PasswordMask
                    name="password"
                    id="password"
                    placeholder="SENHA"
                    value={props.password}
                    onChange={props.handleChange}
                    showButtonContent={'Exibir'}
                    hideButtonContent={'Ocultar'}
                />               
                <ErrorMessage display={props.displayErrorMessage} onCloseErrorMessage={(e) => props.onCloseErrorMessage(e)} message={props.errorMessage} />
                <button type="submit" anime="bg-transition" bt="filled">Entrar</button>
            </form>
            <ModalRecoverPassword />
        </>
    );
}